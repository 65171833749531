<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios'
import { HOST } from '../../constants'
import { router } from '../main'

export default defineComponent({
  name: 'Login',
  data() {
    return {
      password: '',
      loading: false,
    }
  },
  methods: {
    async handleSubmit() {
      const { password } = this

      // Stop here if form is invalid
      if (!password) {
        return
      }
      const this_ = this
      this.loading = true
      await axios
        .post(
          `${HOST}/login`,
          {
            password,
          },
          { withCredentials: true }
        )
        .then(function (res) {
        })
        .catch(function () {
        })
        router.go(0)

    },
  },
})
</script>

<template>
  <div class="loginContainer">
    <form @submit.prevent="handleSubmit">
      <div>
        <label class="passwordLabel" htmlFor="password">Password</label>
        <div class="passwordContainer">
          <input
            v-model="password"
            type="password"
            name="password"
            class="passwordField"
            autocomplete="on"
          />
          <button class="sendPasswordButton" :disabled="loading">></button>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped lang="scss">
.loginContainer {
  background-color: #2b2b2b;
  color: #fff;
  height: 100%;
  width: 100%;
  form {
    font-family: 'Orbitron', sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    width: 50%;
    transform: translate(-50%, -50%);
    align-items: center;

    .passwordLabel {
      animation: color-change 5s infinite;
      text-transform: uppercase;
      font-size: 25px;
    }
    .passwordContainer {
      display: flex;
      justify-content: column;

      .passwordField {
        height: 50px;
        font-size: 50px;
        line-height: 40px;
        padding: 0;
        border: 0;
        width: 100%;
        margin: 5px;
      }
      .sendPasswordButton {
        height: 50px;
        width: 75px;
        padding: 0;
        border: 0;
        color: #fa0791;
        border-color: #fa0791;
        background-color: #2b2b2b;
        text-align: center;
        align-items: center;
        font-size: 1.5em;
        font-weight: bold;
        margin: 5px;
        cursor: pointer;
        border: 5px solid #fa0791;
        border-radius: 2px;
      }
      .sendPasswordButton:hover {
        opacity: 0.8;
      }
    }
  }
}

@keyframes color-change {
  0% {
    color: #ff36e1;
  }
  50% {
    color: #fa0791;
  }
  100% {
    color: #de31a1;
  }
}
</style>
