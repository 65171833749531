<template>
  <router-view />
</template>

<style>
html,
body,
#app {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  font-family: 'Orbitron', sans-serif;
  font-family: 'Sorts Mill Goudy', serif;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-family: 'Junge', serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0px;
  font-size: 20px;
  color: #2c3e50; /* 2c3e50  // #FFF */

background-color: #fff; /* #1a1a1a; // #FFF*/
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  line-height: 1.1;
}
</style>