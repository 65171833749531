import axios from 'axios'
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Login from './components/Login.vue'
import App from './App.vue'

axios.defaults.withCredentials = true

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

createApp(App).use(router).mount('#app')
