
import { defineComponent } from 'vue'
import axios from 'axios'
import { HOST } from '../../constants'
import { router } from '../main'

export default defineComponent({
  name: 'Login',
  data() {
    return {
      password: '',
      loading: false,
    }
  },
  methods: {
    async handleSubmit() {
      const { password } = this

      // Stop here if form is invalid
      if (!password) {
        return
      }
      const this_ = this
      this.loading = true
      await axios
        .post(
          `${HOST}/login`,
          {
            password,
          },
          { withCredentials: true }
        )
        .then(function (res) {
        })
        .catch(function () {
        })
        router.go(0)

    },
  },
})
